export const SESSION_FOR_CMS_PAGE_HEADER_FRAGMENT = `
  fragment sessionForCmsPageHeaderFragment on Session {
    uid
    name
    startTime
    endTime
    likeCount: _likeCount
    dislikeCount: _dislikeCount
    loveCount: _loveCount
  }
`;
